.box {
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
    background-color: rgb(18, 18, 18);
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px
}

.ctrl {
    border-top: 1px solid rgb(99, 99, 99);
}

.textbox {
    background-color: rgb(39, 39, 39);
    color: white;
    resize: none;
    border: none;
    outline: none;
    border-radius: 10px;
    overflow-wrap: break-word;
    width: 90%;
    font-size: 16px;
    padding: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 15vh;
}

.send {
    height: 15vh;
    margin: 0;
    width: 10%;
    border-radius: 0;
    border-bottom-right-radius: 10px;
    padding: 0;
    position: relative;
    /* bottom: calc(15vh/2 - 8px); */
}

.name {
    background-color: rgb(27, 27, 27);
    color: white;
    resize: none;
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    padding: 10px;
}

.name:disabled {
    background-color: rgb(14, 14, 14);
}