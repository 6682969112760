@import url("https://fonts.googleapis.com/css2?family=Syncopate&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #1a1a1a;
    color: #fff;
}

.clearAnchor,
.clearAnchor:link,
.clearAnchor:visited,
.clearAnchor:hover,
.clearAnchor:active {
    color: unset;
    text-decoration: inherit;
    font-weight: inherit;
}

:root {
    scroll-behavior: auto;
}
