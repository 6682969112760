.parallax {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.inset {
    -webkit-box-shadow: inset 0px 0px 37px 12px rgba(0,0,0,0.82); 
    box-shadow: inset 0px 0px 37px 12px rgba(0,0,0,0.82);
}

.titleLarge
{
    font-size: 50px;
    font-weight: bold;
}

.centerParagraph {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

@media only screen and (max-width: 1200px) {
    .centerParagraph {
        width: 100%;
    }
}